
.rank_content {
    width: 100%;
    background-color: #ffffff;
    padding: 0 24px;
    box-sizing: border-box;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 20px;
        border-bottom: 1px solid #F2F2F4;

        .rank_header {
            display: flex;
            align-items: center;

            .rank_item {
                padding: 20px 0;
                margin-right: 20px;
                color: #25263299;
                border-bottom: 2px solid transparent;
                cursor: pointer;
            }

            .active_rank_item {
                color: #252632;
                border-bottom: 2px solid #685EF5;
            }
        }

        .select_time {
            .time-box {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .day-type {
                    margin-right: 6px;
                }
            }

            .sort-box {
                margin-top: 16px;
                display: flex;
                align-items: center;

            }

            .time-box,
            .sort-box {

                .time-label,
                .sort-label {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #252632;
                    line-height: 16px;
                }

                gap: 10px;

                .radio-group {
                    padding: 4px;
                    border-radius: 4px;
                    background-color: #f2f2f4;

                    ::v-deep .el-radio-button__orig-radio+.el-radio-button__inner {
                        background-color: transparent;
                        color: rgba(37, 38, 50, 0.6);
                        font-size: 14px;
                        font-weight: 400;
                        border-color: transparent;
                        box-shadow: none;
                        border-radius: 4px;
                        padding: 2px 24px;
                    }

                    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                        background-color: #ffffff;
                        color: #594FEE;
                    }

                    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner:hover {
                        color: #594FEE;
                    }

                    ::v-deep .el-radio-button__inner:hover {
                        color: #2338e6;
                    }
                }

                .time-filter-box {
                    ::v-deep .el-input__inner {
                        background-color: #f2f2f4;
                        color: #252632;
                        border: none;
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        width: 220px;
                    }

                    ::v-deep .el-input__icon {
                        line-height: 28px;
                    }


                }
            }
            .live_time_box{
                .time-filter-box {
                    ::v-deep .el-input__inner {
                   
                        width: 150px;
                    }
                    ::v-deep .el-date-editor {
                        width: 150px !important;
                    }
                }
            }
        }
    }

    .medio-contain {
        height: calc(100vh - 250px);
        // background-color: #a3a3c4;
        box-sizing: border-box;
        // padding: 0 24px;

        ::v-deep .el-scrollbar .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .medio-list {
            width: 100%;
            height: 100%;
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // gap: 16px;
            // box-sizing: border-box;


        }

        .medio-load {
            display: flex;
            justify-content: center;
            padding: 25px 0;

            span {
                font-weight: 400;
                font-size: 16px;
                color: #888888;
                line-height: 19px;
                cursor: pointer;
            }
        }
    }
}

.ent_name {
    display: flex;
    align-items: center;

    img {
        width: 60px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px
    }

    .ent_info {

        margin-left: 20px;
        margin-right: 30px;

        .ent_name {
            font-size: 15px;
            font-weight: bold;
            margin: 0;
            padding: 0;
        }

        .areas {
            margin: 0;
            padding: 0;
            font-size: 12px;
            margin-top: 10px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 最多显示两行 */
            overflow: hidden;
            text-overflow: ellipsis;
            /* 超出部分显示省略号 */
            line-height: 1.5;
            /* 行高，根据需要调整 */
            max-height: 3em;
            /* 高度根据行高和行数来调整 */
        }
    }
}

.music_box {
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;

    .music_name {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;

        &:hover {
            color: #594FEE;
        }
    }
}

::v-deep .el-table__expand-icon {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    /* 上边透明 */
    border-bottom: 5px solid transparent;
    /* 下边透明 */
    border-left: 5px solid #25263299;
    /* 右边是实心 */
}

::v-deep .el-icon-arrow-right:before {
    content: "";
    display: inline-block;
}

// ::v-deep .el-table .el-table__cell{
//     padding: 0;
// }
::v-deep .el-table__expanded-cell[class*=cell] {
    padding: 0;
}

::v-deep .el-input__inner {
    width: 120px;

}

.show_video {
    display: flex;
    width: 100%;
    height: 225px;
    padding: 16px;
    box-sizing: border-box;
    background-color: #f2f2f2;

    .PreVideo {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #c4c4c4;
        cursor: pointer;
    }

    .video_content {
        flex: 1;
        width: 0;
        flex-shrink: 0;

        overflow: hidden;
        position: relative;

        .content {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            display: flex;

            transition: transform 0.5s ease;

            .video_item {
                width: 110px;
                margin: 0 20px;
                cursor: pointer;
                flex-shrink: 0;

                .image {
                    width: 100%;
                    height: 147px;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .playIcon {
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                    }
                }



                .video_title {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.5;
                    /* 或根据实际需要调整 */
                    max-height: 3em;
                    /* 或 line-height * 2 */
                }

            }
        }



    }

    .NextVideo {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #c4c4c4;
        cursor: pointer;
    }

}

::v-deep .el-table tr {
    cursor: pointer;

}

::v-deep .el-date-editor {
    width: 220px;
}
