
.rank_home_box {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    .rank_item {

        min-height: 86px;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        min-width: 400px;

        .header {
            display: flex;
            align-items: flex-end;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding-bottom: 12px;
            cursor: pointer;
            justify-content: space-between;

            .left {
                flex: 1;
                width: 0;
                display: flex;
                align-items: flex-end;

                h2 {

                    padding: 0px;
                    margin: 0;
                    font-size: 22px;
                    margin: 0;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                }


                .crad {
                    display: flex;
                    padding: 0 10px;
                    cursor: pointer;
                    max-width: 220px;
                    align-items: center;
                    width: 100%;
                    color: rgba(22, 24, 35, 0.5);
                    overflow: hidden;

                    span {
                        margin-left: 10px;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                    }
                }
            }


            .more {
                padding: 0 10px;
            }
        }

        .list_box {
            display: flex;
            flex-direction: column;

            .list_item {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 62px;
                padding: 10px 0;
                box-sizing: border-box;

                .left {
                    display: flex;
                    align-items: center;

                    .ava {
                        margin-left: 10px;
                    }

                    .nickname {
                        width: 190px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1.5;
                        max-height: 3em;
                        padding-right: 10px;
                        box-sizing: border-box;
                    }
                }

                .number {

                    text-align: right;
                    line-height: 1.5;
                }
            }

            .food {
                .ava {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                    border-radius: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }

            }

            .music {

                .ava {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                    border-radius: 2px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                    }
                }

            }

            .ent {
                .ava {
                    width: 32px;
                    height: 42px;
                    margin-right: 8px;
                    border-radius: 2px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                    }
                }

            }
        }
    }

}
