
.tiktok_rank_box {
    .header {
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        box-sizing: border-box;

        .header_item {
            padding: 0 16px;
            cursor: pointer;

            &:nth-of-type(1) {
                border-right: 1px solid #bdbcbc;
            }
        }

        .active_header_item {
            color: #685EF5;
        }

    }

    .rank_box {
        width: 100%;
        margin-top: 20px;
        //  background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
       //  overflow-x: auto !important;

    }
}
